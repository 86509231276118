// src/services/cardService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const cardService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Set authentication token
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(cardService, token);

/**
 * Fetch all user cards
 * @returns {Promise} - API response
 */
export const getUserCards = (data) => {
    const formData = toFormData(data);
    return cardService.post('/user/card/list', formData);
};

/**
 * Add a new card
 * @param {Object} data - Card details
 * @returns {Promise} - API response
 */
export const addCard = (data) => {
    const formData = toFormData(data);
    return cardService.post('/user/card/create', formData);
};

/**
 * Update an existing card
 * @param {Object} data - Updated card details
 * @returns {Promise} - API response
 */
export const updateCard = (id, data) => {
    const formData = toFormData(data);
    return cardService.put(`/user/card/update/${id}`, formData);
};

/**
 * Delete a card
 * @param {number} cardId - Card ID to delete
 * @returns {Promise} - API response
 */
export const deleteCard = (cardId) => {
    return cardService.delete(`/user/card/delete/${cardId}`);
};

export default cardService;
