import React, { useState, useEffect } from 'react';
import { Modal, Form, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { addUserCard, fetchUserCards } from "../features/card/cardActions";
import { toast } from "react-toastify";

const AddCardModal = ({ show, onHide }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validate = () => {
    const errors = {};

    if (!name) errors.name = "Name is required.";
    if (!cardNumber) errors.cardNumber = "Card number is required.";
    if (!expireDate) errors.expireDate = "Expire date is required.";
    if (!securityCode) errors.securityCode = "Security code is required.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    toast.dismiss();
    setLoading(true);

    const cardData = {
      name,
      card_number: cardNumber,
      expire_date: expireDate,
      security_code: securityCode,
    };

    dispatch(addUserCard(cardData))
    .then(() => {
      //toast.success('Card added successfully!');
      setLoading(false);
      onHide();
    })
    .catch(() => {
      toast.error('Error adding card');
      setLoading(false);
    });
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setCardNumber(value.slice(0, 16));
  };

  const handleExpireDateChange = (e) => {
    let value = e.target.value.replace(/[^0-9-]/g, "");
    if (value.length === 4 && !value.includes("-")) {
      value = value + "-";
    }
    value = value.slice(0, 7);

    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    const [year, month] = value.split("-").map(Number);

    if (year && month && (year < currentYear || (year === currentYear && month < currentMonth))) {
      setErrors((prev) => ({
        ...prev,
        expireDate: "Expiry date must be in the future",
      }));
    } else {
      setErrors((prev) => ({ ...prev, expireDate: "" }));
    }

    setExpireDate(value);
  };

  const handleSecurityCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setSecurityCode(value.slice(0, 3));
  };

 
  const { successMessage, errorMessage } = useSelector(
    (state) => state.card
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      setName("");
      setCardNumber("");
      setExpireDate("");
      setSecurityCode("");
      setErrors({});
      let data = { page: 1, limit: 25 };
      dispatch(fetchUserCards(data))
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
  }, [dispatch, successMessage, errorMessage]);

  return (
    <Modal show={show} onHide={onHide} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Add New Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <Form.Group className="mb-4" controlId="formFullName">
                <Form.Label>Name on Card <span>*</span></Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name on card"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="mb-4" controlId="formCardNumber">
                <Form.Label>Card Number <span>*</span></Form.Label>
                <Form.Control
                  type="text"
                  maxLength={16}
                  placeholder="Enter 16 digit card number"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  isInvalid={!!errors.cardNumber}
                />
                <Form.Control.Feedback type="invalid">{errors.cardNumber}</Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col md="6">
                  <Form.Group controlId="formExpiryDate">
                    <Form.Label>Expiry Date <span>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="YYYY-MM"
                      value={expireDate}
                      onChange={handleExpireDateChange}
                      isInvalid={!!errors.expireDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors.expireDate}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formSecurityCode">
                    <Form.Label>Security Code <span>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      maxLength={3}
                      placeholder="CVV"
                      value={securityCode}
                      onChange={handleSecurityCodeChange}
                      isInvalid={!!errors.securityCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.securityCode}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>

            <Col md="12">
              <div className="bttn-group justify-content-center">
                <Link onClick={onHide} className="cutsom-bttn cancel-bttn" type="button">
                  Cancel
                </Link>
                <Button className="cutsom-bttn" type="submit" disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin size="lg" className="spinner-icon" />
                  ) : (
                    'Save Card'
                  )}
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddCardModal;
