// src/features/card/cardReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getCardsStart, getCardsSuccess, getCardsFailure,
    addCardStart, addCardSuccess, addCardFailure,
    updateCardStart, updateCardSuccess, updateCardFailure,
    deleteCardStart, deleteCardSuccess, deleteCardFailure,
} from './cardActions';

const initialState = {
    cards: [],
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const cardReducer = createReducer(initialState, (builder) => {
    builder
        // Clear Messages
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        // Get Cards
        .addCase(getCardsStart, (state) => {
            state.loading = true;
            state.cards = [];
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getCardsSuccess, (state, action) => {
            state.loading = false;
            state.cards = action.payload.data;
            state.successMessage = null;
        })
        .addCase(getCardsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        // Add Card
        .addCase(addCardStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(addCardSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(addCardFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        // Update Card
        .addCase(updateCardStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(updateCardSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(updateCardFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        // Delete Card
        .addCase(deleteCardStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(deleteCardSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(deleteCardFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default cardReducer;
