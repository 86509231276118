// src/features/card/cardActions.js
import { createAction } from '@reduxjs/toolkit';
import {
    getUserCards,
    addCard,
    updateCard,
    deleteCard
} from '../../services/cardService';

// Action Creators
export const clearMessages = createAction('card/clearMessages');

// Get Cards
export const getCardsStart = createAction('card/getCardsStart');
export const getCardsSuccess = createAction('card/getCardsSuccess');
export const getCardsFailure = createAction('card/getCardsFailure');

// Add Card
export const addCardStart = createAction('card/addCardStart');
export const addCardSuccess = createAction('card/addCardSuccess');
export const addCardFailure = createAction('card/addCardFailure');

// Update Card
export const updateCardStart = createAction('card/updateCardStart');
export const updateCardSuccess = createAction('card/updateCardSuccess');
export const updateCardFailure = createAction('card/updateCardFailure');

// Delete Card
export const deleteCardStart = createAction('card/deleteCardStart');
export const deleteCardSuccess = createAction('card/deleteCardSuccess');
export const deleteCardFailure = createAction('card/deleteCardFailure');

// Thunks

/**
 * Fetch user cards
 */
export const fetchUserCards = (formData) => async (dispatch) => {
    dispatch(getCardsStart());
    try {
        const response = await getUserCards(formData);
        if (response.data.status === false) {
            dispatch(getCardsFailure(response.data));
        } else {
            dispatch(getCardsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getCardsFailure(error.message));
    }
};

/**
 * Add a new card
 */
export const addUserCard = (cardData) => async (dispatch) => {
    dispatch(addCardStart());
    try {
        const response = await addCard(cardData);
        if (response.data.status === false) {
            dispatch(addCardFailure(response.data));
        } else {
            dispatch(addCardSuccess(response.data));
        }
    } catch (error) {
        dispatch(addCardFailure(error.message));
    }
};

/**
 * Update a card
 */
export const updateUserCard = (cardId, cardData) => async (dispatch) => {
    dispatch(updateCardStart());
    try {
        const response = await updateCard(cardId, cardData);
        if (response.data.status === false) {
            dispatch(updateCardFailure(response.data));
        } else {
            dispatch(updateCardSuccess(response.data));
        }
    } catch (error) {
        dispatch(updateCardFailure(error.message));
    }
};

/**
 * Delete a card
 */
export const deleteUserCard = (cardId) => async (dispatch) => {
    dispatch(deleteCardStart());
    try {
        const response = await deleteCard(cardId);
        if (response.data.status === false) {
            dispatch(deleteCardFailure(response.data));
        } else {
            dispatch(deleteCardSuccess(response.data));
        }
    } catch (error) {
        dispatch(deleteCardFailure(error.message));
    }
};
