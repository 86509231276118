import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Visa from "../assets/images/profile/visa.svg";
import MasterCard from "../assets/images/profile/master-card.svg";
import AmericanExpress from "../assets/images/profile/american-express.svg";
import Discover from '../assets/images/profile/discover.svg'  
//import Maestro from '../assets/images/profile/maestro.svg'
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PlusIcon from "../assets/images/profile/plus.svg";
import BackImg from "../assets/images/cart/back-icon.svg";
import EditIcon from "../assets/images/cart/edit-icon.svg";
import DeleteIcon from "../assets/images/cart/delete-icon.svg";
import Skeleton from "react-loading-skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { useDispatch, useSelector } from "react-redux";
import { fetchUserCards, deleteUserCard, addUserCard, updateUserCard } from "../features/card/cardActions";
import { toast } from "react-toastify";


function PaymentInfoComp() {
  const [isActive, setIsActive] = useState(false);

  const [formTitle, setFormTitle] = useState("Add New Credit/Debit Card");
  const [name, setName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expireDate, setExpireDate] = useState("");
  const [securityCode, setSecurityCode] = useState("");
  
  const [selectedCard, setSelectedCard] = useState(null);

  const [errors, setErrors] = useState({});

  const handleToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  const dispatch = useDispatch();
  const { cards, loading, successMessage, errorMessage } = useSelector(
    (state) => state.card
  );

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      setName("");
      setCardNumber("");
      setExpireDate("");
      setSecurityCode("");
      setSelectedCard("");
      setFormTitle("Add New Credit/Debit Card")
      setErrors({});
      setIsActive(false);
      let data = { page: 1, limit: 25 };
      dispatch(fetchUserCards(data));
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
  }, [successMessage, errorMessage, dispatch]);

  useEffect(() => {
    let data = { page: 1, limit: 25 };
    dispatch(fetchUserCards(data));
  }, [dispatch, isActive]);

  const handleDelete = (id) => {
    dispatch(deleteUserCard(id));
  };

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!name) errors.name = "Name is required.";
    if (!cardNumber) errors.cardNumber = "Email is required.";
    if (!expireDate) errors.expireDate = "Expire date is required.";
    if (!securityCode) errors.securityCode = "security code is required.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch
    let cardData = {
      name: name,
      card_number: cardNumber,
      expire_date: expireDate,
      security_code: securityCode,
    };
    
    if (selectedCard) {
      // Update card logic
      dispatch(updateUserCard(selectedCard.id, cardData));
    } else {
      // Add new card logic
      dispatch(addUserCard(cardData));
    }
  };

  const handleCardNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    setCardNumber(value.slice(0, 16)); // Limit to 16 digits
  };

  const handleExpireDateChange = (e) => {
    let value = e.target.value.replace(/[^0-9-]/g, ""); // Allow only numbers and "-"
    
    if (value.length === 4 && !value.includes("-")) {
      value = value + "-"; // Auto-add "-" after YYYY
    }
  
    value = value.slice(0, 7); // Ensure format stays within YYYY-MM
  
    // Validate that the entered date is in the future
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1; // JavaScript months are 0-based
  
    const [year, month] = value.split("-").map(Number);
  
    if (
      year && month &&
      (year < currentYear || (year === currentYear && month < currentMonth))
    ) {
      setErrors((prev) => ({
        ...prev,
        expireDate: "Expiry date must be in the future",
      }));
    } else {
      setErrors((prev) => ({ ...prev, expireDate: "" }));
    }
  
    setExpireDate(value);
  };
  
  const handleSecurityCodeChange = (e) => {
    const value = e.target.value.replace(/\D/g, ""); // Allow only numbers
    setSecurityCode(value.slice(0, 3)); // Limit to 3 digits
  };

  const handleEdit = (card) => {
    setSelectedCard(card); // Store selected card
    setName(card.card_name);
    setExpireDate(card.expire_date);
    setSecurityCode(""); // Keep CVV empty for security
    setFormTitle('Edit Credit/Debit Card')
    handleToggle(); // Show form
  };

  return (
    <>
      <div className={`${isActive ? "isHide" : ""}`}>
        <div className="tab-content-header">
          <h3>Payment Info</h3>
          <Link to="" className="cutsom-bttn" onClick={handleToggle}>
            <img src={PlusIcon} alt="" /> Add New Card
          </Link>
        </div>
        <div className="tab-content-body">
          <div className="card-list">
            {loading ? ( // Show skeletons while loading
              <>
                {Array.from({ length: 4 }).map((_, index) => (
                  <div className="card-list-item" key={index}>
                    <div className="card-cell">
                      <Skeleton width={50} height={30} />
                    </div>
                    <div className="card-cell">
                      <span>
                        <Skeleton width={80} />
                      </span>
                      <h4>
                        <Skeleton width={120} />
                      </h4>
                    </div>
                    <div className="card-cell">
                      <span>
                        <Skeleton width={100} />
                      </span>
                      <h4>
                        <Skeleton width={150} />
                      </h4>
                    </div>
                    <div className="card-cell">
                      <span>
                        <Skeleton width={80} />
                      </span>
                      <h4>
                        <Skeleton width={120} />
                      </h4>
                    </div>
                    <div className="card-cell flex-row">
                      <Skeleton circle width={30} height={30} />
                      <Skeleton
                        circle
                        width={30}
                        height={30}
                        style={{ marginLeft: 10 }}
                      />
                    </div>
                  </div>
                ))}
              </>
            ) : cards?.data?.length > 0 ? ( // If cards exist, render them
              cards.data.map((row, index) => (
                <div className="card-list-item" key={index}>
                  <div className="card-cell">
                    <img
                      className="card-img"
                      src={
                        row.card_type === "visa"
                          ? Visa
                          : row.card_type === "mastercard"
                          ? MasterCard
                          : row.card_type === "amex"
                          ? AmericanExpress
                          : row.card_type === "discover"
                          ? Discover
                          : row.card_type === "diners"
                          ? AmericanExpress
                          : row.card_type === "jcb"
                          ? AmericanExpress
                          : null
                      }
                      alt={row.card_type}
                    />
                  </div>
                  <div className="card-cell">
                    <span>Card on Name</span>
                    <h4>{row.card_name}</h4>
                  </div>
                  <div className="card-cell">
                    <span>Card Number</span>
                    <h4>**** **** **** {row.card_number}</h4>
                  </div>
                  <div className="card-cell">
                    <span>Expiry Date</span>
                    <h4>{row.expire_date}</h4>
                  </div>
                  <div className="card-cell flex-row">
                    <Link className="edit-btn" to="" onClick={() => handleEdit(row)}>
                      <img src={EditIcon} alt="Edit" />
                    </Link>
                    <Link
                      className="delete-btn"
                      to=""
                      onClick={() => handleDelete(row.id)}
                    >
                      <img src={DeleteIcon} alt="Delete" />
                    </Link>
                  </div>
                </div>
              ))
            ) : (
              <p>No card found</p> // Show message if no cards exist
            )}
          </div>
        </div>
      </div>
      <div className={`${isActive ? "" : "isHide"}`}>
        <div className="tab-content-header">
          <h3>
            <Link to="" className="backToList" onClick={handleToggle}>
              <img src={BackImg} alt="" />
            </Link>{" "}
            {formTitle}
          </h3>
        </div>
        
        <div className="new-address">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col lg="6">
                <Form.Group className="mb-4" controlId="formFullNamel">
                  <Form.Label>
                    Name on Card <span>*</span>
                  </Form.Label>

                  <Form.Control
                    type="text"
                    placeholder="Enter name on card"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    isInvalid={!!errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-4" controlId="formFullNamel">
                  <Form.Label>
                    Card Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    maxLength={16}
                    placeholder="Enter 16 digit card number"
                    value={cardNumber}
                    onChange={handleCardNumberChange}
                    isInvalid={!!errors.cardNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cardNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Row>
                  <Col md="6">
                    <Form.Group controlId="formFullNamel">
                      <Form.Label>
                        Expiry Date <span>*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="YYYY-MM"
                        value={expireDate}
                        onChange={handleExpireDateChange}
                        isInvalid={!!errors.expireDate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.expireDate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group controlId="formFullNamel">
                      <Form.Label>
                        Security Code <span>*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        maxLength={3}
                        placeholder="CVV"
                        value={securityCode}
                        onChange={handleSecurityCodeChange}
                        isInvalid={!!errors.securityCode}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.securityCode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col md="12">
                <div className="bttn-group">
                  <Link
                    className="cutsom-bttn cancel-bttn"
                    onClick={handleToggle}
                  >
                    Cancel
                  </Link>

                  <Button className="cutsom-bttn" type="submit" disabled={loading}>
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="lg"
                      className="spinner-icon"
                    />
                  ) : (
                    <>
                     Save Card
                    </>
                  )}
                </Button>


                
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </>
  );
}
export default PaymentInfoComp;
