import React, { useState, useEffect } from "react";
import { Row, Col, Table, Form } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import BackButton from "../assets/images/back-icon.svg";
import MasterCard from "../assets/images/profile/master-card.svg";
import Visa from "../assets/images/profile/visa.svg";
import AmericanExpress from "../assets/images/profile/american-express.svg";
import Discover from '../assets/images/profile/discover.svg'  

// import NetBanking from '../assets/images/profile/net-banking.svg'
// import Stripe from '../assets/images/profile/stripe.svg'
// import Discover from '../assets/images/profile/discover.svg'
// import Paypal from '../assets/images/profile/paypal.svg'

import { useDispatch, useSelector } from "react-redux";
import {
  fetchLocalCheckoutInfo,
  clearLocalCheckout,
} from "../features/checkout/checkoutActions";
import { getAddresses } from "../features/user/userActions";
import { fetchUserCards } from "../features/card/cardActions";

import {
  clearOrderMessages,
  createOrder,
} from "../features/order/orderActions";

import { formatPrice, addressStringCreate } from "../helpers/helpers";

import AddAddressModal from "./AddAddressModal";
import EditAddressModal from "./EditAddressModal";

import AddCardModal from "./AddCardModal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

const CheckoutComp = () => {
  // Store address
  const [showAddAddressModal, setShowAddAddressModal] = useState(false);
  const [showEditAddressModal, setShowEditAddressModal] = useState(false);
  const [editAddress, setEditAddress] = useState("");


  const [showAddCardModal, setShowAddCardModal] = useState(false);
  const [selectedCardId, setSelectedCardId] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { checkoutOrder } = useSelector((state) => state.checkout);
  const { addresses } = useSelector((state) => state.user);
  const { loading, orderDetails, successMessage, errorMessage } = useSelector(
    (state) => state.order
  );
  const { cards, loading: cardLoading } = useSelector((state) => state.card);

  useEffect(() => {
    dispatch(fetchLocalCheckoutInfo());
  }, [dispatch]);

  useEffect(() => {
    if (showAddAddressModal === false || showEditAddressModal === false) {
      let data = { page: 1, limit: 25 };
      dispatch(getAddresses(data));
      dispatch(fetchUserCards(data));
    }
  }, [dispatch, showAddAddressModal, showEditAddressModal]);

  useEffect(() => {
    if (cards?.data?.length > 0) {
      setSelectedCardId(cards.data[0].id); // Select first card by default
    }
  }, [cards]);

  const handleEditAddress = (addressData) => {
    setEditAddress(addressData);
    setShowEditAddressModal(true);
  };

  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    // Set the default address if available
    const defaultAddress = addresses?.data?.find(
      (address) => address.is_default === 1
    );
    if (defaultAddress) {
      setSelectedAddress(defaultAddress); // Set the default address on load
    }
  }, [addresses]); // Run effect when addresses change

  const handleAddressChange = (address) => {
    setSelectedAddress(address); // Update state with the selected address
  };

  const handleCreateOrder = () => {
    if(selectedCardId == null){
      toast.error('Please choose payment method.', { autoClose: 3000, hideProgressBar: true });
      return false;
    }

    const updatedOrder = { ...checkoutOrder, address: selectedAddress };
    let formData = {
      cart_items: updatedOrder.cart_items
        ? JSON.stringify(updatedOrder.cart_items)
        : "",
      address: updatedOrder.address ? JSON.stringify(updatedOrder.address) : "",
      promocode: updatedOrder.promocode
        ? JSON.stringify(updatedOrder.promocode)
        : "",
      tax_rate: updatedOrder.tax_rate
        ? JSON.stringify(updatedOrder.tax_rate)
        : "",
      pcid: selectedCardId
    };
    dispatch(createOrder(formData));
  };

  useEffect(() => {
    if (orderDetails) {
      if (successMessage) {
        toast.success(successMessage, {
          autoClose: 3000,
          hideProgressBar: true,
        });
        dispatch(clearOrderMessages());
        dispatch(clearLocalCheckout());
        navigate("/profile/my-orders");
      }
    }

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearOrderMessages());
    }
  }, [dispatch, navigate, errorMessage, successMessage, orderDetails]);



  return (
    <>
      <div className="checkout-cart">
        <Row>
          <Col xl={8}>
            <div className="checkout-cart-box">
              <h3>Checkout ({checkoutOrder?.cart_items?.length})</h3>
              <div className="checkout-list">
                {checkoutOrder?.cart_items?.length > 0 && (
                  <>
                    {checkoutOrder?.cart_items?.map((item, index) => (
                      <div className="checkout-item" key={index}>
                        <div className="cart-box">
                          <div className="cart-box-img">
                            <Link to={"/products/" + item.slug}>
                              <img
                                src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${item.image}`}
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="cart-box-content">
                            <span>{item.category_title}</span>
                            <h2>
                              <Link to={"/products/" + item.slug}>
                                {item.title}
                              </Link>
                            </h2>
                            <Link to={"/products/" + item.slug}>
                              {item.variation_title}
                            </Link>
                          </div>
                        </div>
                        <h4 className="price-value">
                          {item.currency}
                          {formatPrice(item.sub_total)}
                        </h4>
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="address-method">
                <h4>Delivery Address</h4>
                <div className="address-method-header">
                  <h5>Your Addresses</h5>
                  <Link to="" onClick={() => setShowAddAddressModal(true)}>
                    + Add New Address
                  </Link>
                </div>
                <div className="address-method-body" key="k1">
                  {addresses?.data?.length > 0 && (
                    <>
                      {addresses?.data?.map((address, indexAddress) => (
                        <div key={"k" + indexAddress} className="radio-group">
                          <div className="gender-group">
                            <Form.Check
                              type="radio"
                              name="address"
                              checked={selectedAddress?.id === address.id} // Check if this address is selected
                              onChange={() => handleAddressChange(address)} // Handle selection
                              className="form-check custom-checkbox"
                              id={`address-${indexAddress}`}
                            />
                            <div className="radio-info">
                              <h3>{address.name}</h3>
                              <p>{addressStringCreate(address)}</p>
                            </div>
                            <Link
                              to=""
                              onClick={() => handleEditAddress(address)}
                              className="edit-address"
                            >
                              Edit Address
                            </Link>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
              <div className="address-method">
                <h4>Payment Method</h4>
                <div className="address-method-header">
                  <h5>Credit & Debit Card</h5>
                  <Link to="" onClick={() => setShowAddCardModal(true)}>+ Add New Card</Link>
                </div>

                {!cardLoading && (
                  <div className="address-method-body p-2" key="k3">
                    {cards?.data?.length > 0 ? (
                      cards.data.map((row) => (
                        <div className="radio-group" key={`card_${row.id}`}>
                          <div className="gender-group payment-card-group">
                            <Form.Check
                              type="radio"
                              name="payment_method"
                              checked={selectedCardId === row.id}
                              className="form-check custom-checkbox"
                              id={`card-${row.id}`}
                              onChange={() => setSelectedCardId(row.id)}
                            />
                            <div className="radio-info">
                              <div className="card-info-box">
                                <img
                                  className="card-info-box-icon"
                                  src={
                                    row.card_type === "visa"
                                      ? Visa
                                      : row.card_type === "mastercard"
                                      ? MasterCard
                                      : row.card_type === "amex"
                                      ? AmericanExpress
                                      : row.card_type === "discover"
                                      ? Discover
                                      : row.card_type === "diners"
                                      ? AmericanExpress
                                      : row.card_type === "jcb"
                                      ? AmericanExpress
                                      : null
                                  }
                                  alt="Card"
                                />
                                <div className="card-info-box-text">
                                  <p>{row.card_name}</p>
                                  <p>**** **** **** {row.card_number}</p>
                                </div>
                                {/* <Form.Group>
                                  <Form.Control
                                    type="tel"
                                    maxLength="3"
                                    placeholder="CVV"
                                    className="cvv-input"
                                  />
                                </Form.Group> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="mb-0">No card found.</p>
                    )}
                  </div>
                )}
              </div>
              {/* <div className="address-method">               
                  <div className='address-method-header'>
                    <h5>Another Payment Method</h5>
                  </div>
                  <div className='address-method-body'>
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="anotherpayment"
                          className='form-check custom-checkbox'
                          id="NetBanking" 
                          checked
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon' src={NetBanking} alt="" />
                              <h3>Net Banking</h3>        
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Select Your Bank <span>*</span></Form.Label>
                              <Form.Select className='form-control' aria-label="Default select example">
                                <option>Select Your Bank</option>
                                <option value="1">SBI</option>
                                <option value="2">ICICI</option>
                                <option value="3">HDFC</option>
                              </Form.Select>
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="anotherpayment"
                          className='form-check custom-checkbox'
                          id="Paypal" 
                          checked
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon' src={Paypal} alt="" />
                              <h3>Paypal</h3>        
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Please enter your Paypal ID <span>*</span></Form.Label>
                              <Form.Control className='form-control' type="text" placeholder="Enter Paypal ID" />
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="anotherpayment"
                          className='form-check custom-checkbox'
                          id="Stripe" 
                          checked
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon' src={Stripe} alt="" />
                              <h3>Stripe</h3>        
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Please enter your Stripe ID <span>*</span></Form.Label>
                              <Form.Control className='form-control' type="text" placeholder="Enter Stripe ID" />
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="anotherpayment"
                          className='form-check custom-checkbox'
                          id="Stripe" 
                          checked
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon discover-icon' src={Discover} alt="" />
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Please enter your Discover ID <span>*</span></Form.Label>
                              <Form.Control className='form-control' type="text" placeholder="Enter Discover Id" />
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                  </div>
              </div> */}

              {/* <div className="address-method">               
                  <div className='address-method-header'>
                    <h5>Pay via Paypal</h5>
                  </div>
                  <div className='address-method-body' key="k1">
                    
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="payment_method"
                          className='form-check custom-checkbox'
                          id="Paypal" 
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon' src={Paypal} alt="" />
                              <h3>Paypal</h3>        
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Please enter your Paypal ID <span>*</span></Form.Label>
                              <Form.Control className='form-control' type="text" placeholder="Enter Paypal ID" />
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                    
                    <div className="radio-group">
                      <div className="gender-group"> 
                        <Form.Check
                          type="radio"
                          name="anotherpayment"
                          className='form-check custom-checkbox'
                          id="Stripe" 
                          checked
                        />
                        <div className='radio-info'>
                          <div className="payment-box">
                            <div className='payment-box-text'>
                              <img className='card-info-box-icon discover-icon' src={Discover} alt="" />
                            </div>                  
                            <Form.Group className='form-group'>
                              <Form.Label>Please enter your Discover ID <span>*</span></Form.Label>
                              <Form.Control className='form-control' type="text" placeholder="Enter Discover Id" />
                            </Form.Group>
                          </div>
                        </div>                     
                      </div>
                    </div>
                  </div>
              </div> */}
            </div>
          </Col>
          <Col xl={4}>
            <div className="coupon-code-box">
              {/* <div className='box-header'>
                <h3>Coupon Code</h3>
                <p>Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
              </div>
              <div className="discount-code">
                <h4>Apply Discount Code</h4>
                <Form>
                  <Form.Group controlId="formCoupon">
                    <Form.Control type="text" placeholder="Enter Promo Code" />
                  </Form.Group>
                  <Button type='submit' className="cutsom-bttn" block>Apply</Button>
                </Form>
              </div> */}

              <div className="summary-box">
                <div className="box-header">
                  <h3>Summary</h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </p>
                </div>
                <h4>Order Summary</h4>
                <Table>
                  <tbody>
                    <tr>
                      <td>Items Total({checkoutOrder?.cart_items?.length})</td>
                      <td>
                        {checkoutOrder?.currency}
                        {checkoutOrder?.total_item_amount}
                      </td>
                    </tr>

                    <tr>
                      <td>Discounts</td>
                      <th>
                        -{checkoutOrder?.currency}
                        {checkoutOrder?.discount}
                      </th>
                    </tr>

                    {checkoutOrder?.promocode !== null && (
                      <tr>
                        <td>{checkoutOrder?.display_promocode_title}</td>
                        <th>
                          -{checkoutOrder?.currency}
                          {checkoutOrder?.promocode_discount_amount}
                        </th>
                      </tr>
                    )}

                    {checkoutOrder?.tax_amount > 0 && (
                      <tr>
                        <td>Tax</td>
                        <td className="discounts">
                          +{checkoutOrder?.currency}
                          {checkoutOrder?.tax_amount}
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th>Order Total</th>
                      <th>
                        {checkoutOrder?.currency}
                        {checkoutOrder?.order_total}
                      </th>
                    </tr>
                  </tbody>
                </Table>
                <div className="return-continue-group">
                  <Link className="cutsom-bttn back-button" to="/products">
                    <img src={BackButton} alt="" /> Return to Shipping
                  </Link>
                  <Link
                    to=""
                    className="cutsom-bttn"
                    onClick={(event) => {
                      event.preventDefault();
                      handleCreateOrder();
                    }}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Continue to Payment"
                    )}
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <AddAddressModal
        show={showAddAddressModal}
        onHide={() => setShowAddAddressModal(false)}
      />
      {editAddress !== "" && (
        <EditAddressModal
          show={showEditAddressModal}
          onHide={() => setShowEditAddressModal(false)}
          addressData={editAddress}
        />
      )}

      <AddCardModal
        show={showAddCardModal}
        onHide={() => setShowAddCardModal(false)}
      />
      
    </>
  );
};

export default CheckoutComp;
